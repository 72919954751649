import React, { useEffect, useState } from 'react';
import { Box, IconButton, Button, Modal, Snackbar, Alert, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './BlackFridayPopup.module.css';
// import blackfridayLogo from '../../assets/blueFridayLogo.svg';
import { useTranslation } from 'react-i18next';

const BlackFridayPopup = () => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const popupShown = sessionStorage.getItem('popupShown');
    const popupShownLocalStorage = localStorage.getItem('popupShown');
    setTimeout(() => {
      if (!popupShown) {
        setOpen(true);
      }
    }, 500);

    if (popupShownLocalStorage) {
      localStorage.removeItem("popupShown");
    }

  }, []);

  const handleClose = () => {
    setOpen(false);
    sessionStorage.setItem('popupShown', 'true');
  };


  const handleCopyCode = () => {
    navigator.clipboard.writeText('BlueFriday');
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className={styles.modalBoxContainer}>

          <Slide direction="up" in={open} mountOnEnter unmountOnExit timeout={1000}>
            <Box className={styles.modalBox}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={`${styles.closeButton} ${styles.closeButton}`}
              >
                <CloseIcon />
              </IconButton>
              <div className={styles.BackgroundimageWords}>
                BLUE FRIDAY


              </div>
              <h1 className={t === "ar" ? styles.DiscountCode : styles.DiscountCodeEN}>
                {t("Black_Friday_Discount")}
              </h1>
              <p className={styles.Courses}>{t("Black_Friday_OnCourses")}</p>
              <p className={styles.Courses}>{t("Black_Friday_programs")}</p>
              <Button
                variant="contained"
                className={styles.copyButton}
                onClick={handleCopyCode}
              >
                <p className="m-0">{t("Black_Friday_UseProgram")}</p>
                <p className={styles.BlueFridayCode}>BlueFriday</p>
              </Button>
            </Box>
          </Slide>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {t("Black_Friday_copy")}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BlackFridayPopup;
