import Diversity3Icon from "@mui/icons-material/Diversity3";
import GroupsIcon from "@mui/icons-material/Groups";
import {
    Alert,
    Box,
    CircularProgress,
    Pagination,
    Snackbar,
    Stack,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import UserCards from "../../../components/UserCards/UserCards.jsx";
import { getCompanyGroups, getUsers } from "../../../service/businessAPI/business.js";
import styles from "./UsersTracking.module.css";

export default function UsersTracking() {
    const [data, setData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [Loading, setLoading] = useState(false);
    const [optionsDataGroups, setOptionsDataGroups] = useState([]);
    // handle the states of employs
    const [employsState, setEmploysState] = useState("");
    // handle group department selection
    const [selectGroup, setSelectGroup] = useState("");

    // handle the state of the search bar
    const [search, setSearch] = useState("");
    const clearBtnRef = useRef(null);

    const [state, setState] = useState({
        open: false,
        vertical: "top",
        horizontal: "right",
    });

    //handle the filter of employ status
    const handleEmployState = (e) => {
        const selectedState = e.target.value;
        setEmploysState(selectedState);
        setSearchparams({ ...searchparams, user_status: selectedState });
    };

    //handle the filter of groups
    const handleGroupFilter = (e) => {
        const selectedGroupOfUsers = e.target.value;
        setSelectGroup(selectedGroupOfUsers);
        setSearchparams({ ...searchparams, group_contains: selectedGroupOfUsers });
    };

    const { vertical, horizontal, open } = state;

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };
    const [searchparams, setSearchparams] = useSearchParams();

    // const eyouthPublicClient = JSON.parse(
    //     localStorage.getItem("userPublic_stu") || {}
    // );
    // const companyName = localStorage.getItem("company_name");
    const companyName = "eyouth";
    // let primarycolor = localStorage.getItem("primaryColor");
    let primarycolor = "#243a93";

    // Get the users inside the company
    useEffect(() => {
        // setLoading(true);
        getUsers(
            `${currentPage}`,
            companyName,
            "10",
            employsState,
            search,
            selectGroup
        )
            .then((result) => {
                setData(result);
                setLoading(true);
            })
            .catch((error) => {
                console.error("API Error: ", error);
            });
        setLoading(false);
    }, [currentPage, companyName, employsState, selectGroup, search]);

    //handle filter by name & email
    const handleNameAndEmailFilter = async (e) => {
        // console.log('Hello')
        try {
            const result = await getUsers(
                `${currentPage}`,
                companyName,
                "10",
                employsState,
                search,
                selectGroup
            );
            setData(result);
        } catch (error) {
            console.error("Error", error);
        }
    };

    const handleClearSearch = () => {
        setSearch("");
        setEmploysState("");
        setSelectGroup("");
        const initialParams = { page_number: currentPage };
        setSearchparams(initialParams);
        handleNameAndEmailFilter();
    };

    // Get the groups inside the company
    useEffect(() => {
        getCompanyGroups(companyName)
            .then((result) => {
                const formattedOptionsGroups = result.data.map((item) => ({
                    value: item.group_name,
                    label: item.group_name,
                }));
                setOptionsDataGroups(formattedOptionsGroups);
            })
            .catch((error) => {
                console.error("API Error:", error);
            });
    }, [companyName]);

    return (
        <>
            <Snackbar
                key={vertical + horizontal}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={20000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                    Your Request is being processed, Please don't refresh the page.
                </Alert>
            </Snackbar>

            <div className={styles.businessInfoCards}>
                <UserCards
                    count={data.count}
                    title="All Users"
                    Icon={<GroupsIcon style={{ fontSize: "2.5rem" }} />}
                />
                <UserCards
                    count={optionsDataGroups.length}
                    title="All Groups"

                    Icon={<Diversity3Icon style={{ fontSize: "2.5rem" }} />}
                />
            </div>

            <div className={styles.filterContainer}>
                {/* Department/Group Filter */}
                <label className={styles.groupFilter}>
                    <h6>Group</h6>
                    <select
                        value={selectGroup}
                        onChange={handleGroupFilter}
                        name="All Groups"
                        className={styles.filterBtn}
                    >
                        <option value="" selected>
                            All Groups
                        </option>
                        {optionsDataGroups.map((group, i) => (
                            <option key={i} value={group.value}>
                                {group.label}
                            </option>
                        ))}
                    </select>
                </label>

                {/* employs state active or inactive */}
                <label className={styles.groupFilter}>
                    <h6>State</h6>
                    <select
                        value={employsState}
                        onChange={handleEmployState}
                        name="user state"
                        className={styles.filterBtn}
                    >
                        <option value="" selected>
                            All States
                        </option>
                        <option value="True">Active</option>
                        <option value="False">Inactive</option>
                    </select>
                </label>
            </div>

            <div className={styles.searchContainer}>
                <>
                    <Form.Control
                        className={styles.searchField}
                        type="text"
                        placeholder="Search by name, email"
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        value={search}
                    />
                    <button
                        className={styles.clearTableBtn}
                        ref={clearBtnRef}
                        onClick={handleClearSearch}
                        type="button"
                    >
                        X
                    </button>
                </>
                <button
                    onClick={(e) => {
                        handleNameAndEmailFilter();
                        e.preventDefault();
                    }}
                    className={styles.formSubmitBtn}
                    type="submit"
                >
                    Search
                </button>
            </div>

            <div style={{ backgroundColor: "#FAFAFA" }} className="table-responsive">
                <table
                    className="table-striped table table-hover table-bordered table-responsive "
                    style={{ color: "#183696" }}
                >
                    <thead>
                        <tr className={styles.tableHeaders}>
                            <th
                                className={styles.tableHeaders}
                                style={{ color: primarycolor }}
                                scope="col"
                            >
                                ID
                            </th>
                            <th
                                className={styles.tableHeaders}
                                style={{ color: primarycolor }}
                                scope="col"
                            >
                                Name
                            </th>
                            <th
                                className={styles.tableHeaders}
                                style={{ color: primarycolor }}
                                scope="col"
                            >
                                State
                            </th>
                            <th
                                className={styles.tableHeaders}
                                style={{ color: primarycolor }}
                                scope="col"
                            >
                                Email
                            </th>
                            <th
                                className={styles.tableHeaders}
                                style={{ color: primarycolor }}
                                scope="col"
                            >
                                Group Name
                            </th>
                            <th
                                className={styles.tableHeaders}
                                style={{ color: primarycolor }}
                                scope="col"
                            >
                                Last Login
                            </th>
                        </tr>
                    </thead>

                    <tbody style={{ verticalAlign: "middle", position: "relative" }}>
                        {!Loading ? (
                            <tr>
                                <td style={{ textAlign: "center" }}>
                                    <Box
                                        style={{
                                            position: "relative",
                                            top: "50%",
                                            left: "190%",
                                            // transform: "translate(-50%, -50%)",
                                        }}
                                    >
                                        <CircularProgress
                                            size={50}
                                            style={{ color: primarycolor }}
                                        />
                                    </Box>
                                </td>
                            </tr>
                        ) : (
                            data?.results?.map((user, i) => (
                                <tr key={i}>
                                    <th scope="row">{user.id}</th>
                                    <td>
                                        {" "}
                                        <a style={{ textDecorationLine: "underline" }} href={`/userProfile/${user.username}`}>{user.name}</a>
                                    </td>
                                    <td>{user.is_active ? "active" : "inactive"}</td>
                                    <td>{user.email}</td>
                                    <td>{user.group}</td>
                                    <td>
                                        {moment(user.last_login).format("D MMM YYYY, h:mm a")}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            <Stack spacing={2} alignItems={"center"} marginTop={3}>
                <Pagination
                    page={currentPage}
                    siblingCount={2}
                    onChange={handleChange}
                    count={data?.num_pages}
                    sx={{
                        ".Mui-selected": {
                            background: "#183696 !important",
                            color: "white",
                        },
                    }}
                />
            </Stack>
        </>
    );
}
