import React from 'react'
import styles from './UserCards.module.css';

function UserCards(props) {
    let primarycolor = '#183696';

    return (
        <div className={styles.businessInfoCard}>
            <div className={styles.businessNumbers}>
                <h2>{props.count}</h2>
                <p className='text-muted' >{props.title}</p>
            </div>
            <div className={styles.logoCard} style={{ background: primarycolor }}>
                {props.Icon}
            </div>
        </div>
    )
}

export default UserCards