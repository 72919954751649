import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: [],
    loading: false,
    data: false
};

const slice = createSlice({
    name: "addReview",
    initialState,
    reducers: {
        AddReviewRequest: (addReview) => {
            addReview.loading = true;
            addReview.error = false;
        },
        AddReviewCleaC: (addReview) => {
            addReview.error = false;
            addReview.loading = false;
        },
        AddReviewSuccess: (addReview, action) => {
            addReview.loading = false;
            addReview.error = false;
            addReview.data = action.payload;
        },
        AddReviewError: (addReview, action) => {
            addReview.loading = false;
            addReview.error = action.payload;
        }
    }
});

export const { AddReviewClear, AddReviewRequest, AddReviewSuccess, AddReviewError } = slice.actions;

export default slice.reducer;
