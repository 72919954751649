import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: [],
    loading: false,
    data: false
};

const slice = createSlice({
    name: "courseEnrollList",
    initialState,
    reducers: {
        CourseEnrollListRequest: (courseEnrollList) => {
            courseEnrollList.loading = true;
            courseEnrollList.error = false;
        },
        CourseEnrollListClear: (courseEnrollList) => {
            courseEnrollList.error = false;
            courseEnrollList.loading = false;
        },
        CourseEnrollListSuccess: (courseEnrollList, action) => {
            courseEnrollList.loading = false;
            courseEnrollList.error = false;
            courseEnrollList.data = action.payload;
        },
        CourseEnrollListError: (courseEnrollList, action) => {
            courseEnrollList.loading = false;
            courseEnrollList.error = action.payload;
        }
    }
});

export const { CourseEnrollListClear, CourseEnrollListRequest, CourseEnrollListSuccess, CourseEnrollListError } = slice.actions;

export default slice.reducer;
