// reducers.js
import { FETCH_DATA_START, FETCH_DATA_SUCCESS, FETCH_DATA_FAILURE, FETCH_DATA_LOADING } from './userLocationActions';

const initialState = {
    loading: true,
    Current_Location: "EG",
    EG: {
        currency_rate: 1,
        currency: "EGP"
    },
    SA: {
        currency_rate: 5,
        currency: "SAR"
    },
    AE: {
        currency_rate: 5,
        currency: "AED"
    },
    Other: {
        currency_rate: 18,
        currency: "USD"
    },
};

const userLocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA_START:
            return {
                ...state,
                loading: true,
            };
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                loading: true,
                Current_Location: action.payload,
            };
        case FETCH_DATA_LOADING:
            return {
                ...state,
                loading: false
            };
        case FETCH_DATA_FAILURE:
            return {
                ...state,
                error: action.payload,
                Current_Location: "EG",
                loading: false
            };

        default:
            return state;
    }
};

export default userLocationReducer;
