import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locals/EN/translation.json";
import translationAR from "./locals/AR/translation.json";
import intervalPlural from "i18next-intervalplural-postprocessor";

const languages = ["en", "ar"];

const resources = {
  en: { translation: translationEN, },
  ar: { translation: translationAR, },
};

i18n
  .use(intervalPlural)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: localStorage.getItem("i18nextLng") || "ar",
    lng: localStorage.getItem("i18nextLng") || "ar",
    whitelist: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    returnEmptyString: false
  });

export default i18n;
