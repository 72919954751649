import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: [],
    loading: false,
    data: false
};

const slice = createSlice({
    name: "courseDetails",
    initialState,
    reducers: {
        CourseDetailsRequest: (courseDetails) => {
            courseDetails.loading = true;
            courseDetails.error = false;
        },
        CourseDetailsClear: (courseDetails) => {
            courseDetails.error = false;
            courseDetails.loading = false;
        },
        CourseDetailsSuccess: (courseDetails, action) => {
            courseDetails.loading = false;
            courseDetails.error = false;
            courseDetails.data = action.payload;
        },
        CourseDetailsError: (courseDetails, action) => {
            courseDetails.loading = false;
            courseDetails.error = action.payload;
        }
    }
});

export const { CourseDetailsClear, CourseDetailsRequest, CourseDetailsSuccess, CourseDetailsError } = slice.actions;

export default slice.reducer;
