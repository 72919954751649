import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: [],
    loading: false,
    data: false
};

const slice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        AuthRequest: (auth) => {
            auth.loading = true;
            auth.error = false;
        },
        AuthClear: (auth) => {
            auth.error = false;
            auth.loading = false;
        },
        AuthSuccess: (auth, action) => {
            auth.loading = false;
            auth.error = false;
            auth.data = action.payload;
        },
        AuthError: (auth, action) => {
            auth.public_loading = false;
            auth.public_error = action.payload;
        },
        PublicAuthRequest: (auth) => {
            auth.public_loading = true;
            auth.public_error = false;
        },
        PublicAuthClear: (auth) => {
            auth.public_error = false;
            auth.public_loading = false;
        },
        PublicAuthSuccess: (auth, action) => {
            auth.public_loading = false;
            auth.public_error = false;
            auth.public_data = action.payload;
        },
        PublicAuthError: (auth, action) => {
            auth.public_loading = false;
            auth.public_error = action.payload;
        }
    }
});

export const { AuthRequest, AuthClear, AuthSuccess, AuthError, PublicAuthSuccess, PublicAuthRequest, PublicAuthClear, PublicAuthError } = slice.actions;

export default slice.reducer;
