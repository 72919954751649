export const PartnersList = [
  "90516639_115107153445221_2698623042652733440_n.webp",
  "IMG-4337.webp",
  "IMG-4342.webp",
  "IMG-4349.webp",
  "IMG-8379.webp",
  "Ministry_of_Education_(Egypt)_logo_(wikiar).webp",
  "new-logo_web.webp",
  "وزارة-الصحة.webp",
  "155881549716117.webp",
  "1673276248585.webp",
  "GESR-Logo-Colors.webp",
  "IMG-4320.webp",
  "IMG-4321.webp",
  "IMG-4322.webp",
  "IMG-4323.webp",
  "IMG-4324.webp",
  "IMG-4325.webp",
  "IMG-4326.webp",
  "IMG-4327.webp",
  "IMG-4328.webp",
  "IMG-4329.webp",
  "IMG-4330.webp",
  "IMG-4331.webp",
  "IMG-4332.webp",
  "IMG-4333.webp",
  "IMG-4334.webp",
  "IMG-4335.webp",
  "IMG-4336.webp",
  "IMG-4338.webp",
  "IMG-4339.webp",
  "IMG-4340.webp",
  "IMG-4341.webp",
  "IMG-4343.webp",
  "IMG-4344.webp",
  "IMG-4345.webp",
  "IMG-4346.webp",
  "IMG-4347.webp",
  "IMG-4348.webp",
  "IMG-4350.webp",
  "IMG-4351.webp",
  "IMG-4352.webp",
  "IMG-4353.webp",
  "IMG-4354.webp",
  "IMG-4355.webp",
  "IMG-4356.webp",
  "IMG-4357.webp",
  "IMG-8358.webp",
  "IMG-8359.webp",
  "IMG-8360.webp",
  "IMG-8361.webp",
  "IMG-8362.webp",
  "IMG-8363.webp",
  "IMG-8364.webp",
  "IMG-8365.webp",
  "IMG-8366.webp",
  "12249431486_64af0ce01a_b.webp",
  "IMG-8367.webp",
  "IMG-8368.webp",
  "IMG-8369.webp",
  "IMG-8370.webp",
  "IMG-8371.webp",
  "IMG-8372.webp",
  "IMG-8373.webp",
  "IMG-8374.webp",
  "IMG-8375.webp",
  "IMG-8376.webp",
  "IMG-8377.webp",
  "IMG-8378.webp",
  "IMG-8380.webp",
  "IMG-8381.webp",
  "IMG-8382.webp",
  "IMG-8383.webp",
  "IMG-8384.webp",
  "IMG-8385.webp",
  "IMG-8386.webp",
  "IMG-8387.webp",
  "IMG-8388.webp",
  "IMG-8389.webp",
  "IMG-8390.webp",
  "IMG-8391.webp",
  "IMG-8392.webp",
  "IMG-8393.webp",
  "IMG-8394.webp",
  "IOM_Logo (1).webp",
  "L_Oréal-Egypt-36052-1539523158-og (1).webp",
  "Plan_International_Logo_blue.webp",
  "UN-Women-logo-social-media-1024x512-en.webp",
  "Vodafone_2017_logo.webp",
  "Voluntary_Service_Overseas_(VSO)_logo.webp",
  "download (10).webp",
  "download (7).webp",
  "download (8).webp",
  "download (9).webp",
  "maxresdefault.webp",
]
