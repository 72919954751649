import GroupsIcon from "@mui/icons-material/Groups";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Pagination,
    Snackbar,
    Stack,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Select from 'react-select';
import { Form } from "react-bootstrap";
import UserCards from "../../../components/UserCards/UserCards.jsx";
import {
    getAllCoursesSearch,
    getCompanyGroups,
    usersProgress,
} from "../../../service/businessAPI/business.js";
import styles from "./LmsTrack.module.css";

function LmsTrack() {
    const [data, setData] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [optionsDataGroups, setOptionsDataGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    // filter by course
    const [selectedCourse, setSelectedCourse] = useState("");
    const [certificateDelivered, setCertificateDelivered] = useState("all");
    // handle group department selection
    const [selectGroup, setSelectGroup] = useState("");

    // handle the state of the search bar
    const [search, setSearch] = useState("");
    const clearBtnRef = useRef(null);

    // Add a state variable to track whether a filter is selected
    const [filterSelected, setFilterSelected] = useState(false);
    const [encodedCourseKey, setEncodedCourseKey] = useState("");
    const [optionsDataCourses, setOptionsDataCourses] = useState([]);


    // let primarycolor = localStorage.getItem("primaryColor");
    let primarycolor = "#243a93";

    // changing the color of the circular progress
    const getColor = (value) => {
        if (value < 35) {
            return "red";
        } else if (value >= 35 && value > 75) {
            return "blue";
        } else {
            return "red";
        }
    };

    const size = 120,
        thickness = 9,
        secColor = "#d9d9d9";

    const progressSx = {
        borderRadius: "50%",
        boxShadow: `inset 0 0 0 ${(thickness / 44) * size}px ${secColor}`,
    };

    //handle the certificateDelivered select filter
    // const handleCertificateFilter = (e) => {
    //     setCertificateDelivered(e.target.value);
    // };

    const [state, setState] = useState({
        open: false,
        vertical: "top",
        horizontal: "right",
    });

    const { vertical, horizontal, open } = state;

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    //filter by user-name
    const handleUserNameFilter = (e) => {
        const userFilterSearch = e.target.value;
        setSearch(userFilterSearch);
    };

    // const eyouthPublicClient = JSON.parse(
    //     localStorage.getItem("userPublic_stu") || {}
    // );

    // const companyName = localStorage.getItem("company_name");
    const companyName = "eyouth";

    //handle filter by user name
    const handleFilterByUserName = async () => {
        try {
            const encodedCourseKey = decodeURI(selectedCourse)
                .replaceAll(":", "%3A")
                .replaceAll("+", "%2B");
            const result = await usersProgress(
                currentPage,
                companyName,
                `${encodedCourseKey}`,
                `${selectGroup}`,
                search
            );
            setData(result);
        } catch (error) {
            console.error("Error", error);
        }
    };

    const handleClearSearch = () => {
        setSearch("");
        setSelectedCourse("");
        setCertificateDelivered("all");
        setSelectGroup("");
        handleFilterByUserName();
        window.location.reload();
    };

    //handle the filter of groups
    const handleGroupFilter = (e) => {
        const selectedGroupOfUsers = e.target.value;
        setSelectGroup(selectedGroupOfUsers);
    };

    // Function to handle filter changes
    const handleFilterChange = () => {
        // Check if any filter is selected
        if (
            selectedCourse !== "" ||
            certificateDelivered !== "all" ||
            selectGroup !== ""
        ) {
            setFilterSelected(true);
        } else {
            setFilterSelected(false);
        }
    };

    //Get all courses
    useEffect(() => {
        getAllCoursesSearch()
            .then((result) => {
                setLoading(true);

                const formattedOptionsCourses = result.map(item => ({
                    value: item.course_id,
                    label: item.name,
                }))
                setOptionsDataCourses(formattedOptionsCourses)
                setLoading(false);

            })
            .catch((error) => {
                console.error('API Error:', error);
            });
        // eslint-disable-next-line
    }, []);

    // Get the groups inside the company
    useEffect(() => {
        getCompanyGroups(companyName)
            .then((result) => {
                const formattedOptionsGroups = result.data.map((item) => ({
                    value: item.group_name,
                    label: item.group_name,
                }));
                setOptionsDataGroups(formattedOptionsGroups);
            })
            .catch((error) => {
                console.error("API Error:", error);
            });
    }, [companyName]);

    // Generate encodedCourseKey whenever selectedCourse changes
    useEffect(() => {
        const generateEncodedCourseKey = () => {
            const encodedKey = decodeURI(selectedCourse)
                .replaceAll(":", "%3A")
                .replaceAll("+", "%2B");
            setEncodedCourseKey(encodedKey);
        };
        generateEncodedCourseKey();
    }, [selectedCourse]);

    // Get the users progress and details
    useEffect(() => {
        setLoading(true);
        handleFilterChange();
        const encodedCourseKey = decodeURI(selectedCourse)
            .replaceAll(":", "%3A")
            .replaceAll("+", "%2B");
        usersProgress(
            currentPage,
            companyName,
            `${encodedCourseKey}`,
            `${selectGroup}`,
            search
        )
            .then((result) => {
                setData(result);
                // console.log('datalms', data.data.length)
            })
            .catch((error) => {
                console.error("Users API all Info Error: ", error);
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, [companyName, selectedCourse, currentPage, selectGroup, search]);

    return (
        <>
            <Snackbar
                key={vertical + horizontal}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={20000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                    Your Request is being processed, Please don't refresh the page.
                </Alert>
            </Snackbar>

            <div className={styles.businessInfoCards}>
                <UserCards
                    count={optionsDataGroups.length}
                    title="Groups"
                    Icon={<GroupsIcon style={{ fontSize: "40px" }} />}
                />
            </div>

            <div className={styles.searchContainer}>
                <>
                    <Form.Control
                        className={styles.searchField}
                        onChange={handleUserNameFilter}
                        type="text"
                        placeholder="Search by User name"
                        value={search}
                    />
                    <button
                        className={styles.clearTableBtn}
                        ref={clearBtnRef}
                        onClick={handleClearSearch}
                        type="button"
                    >
                        X
                    </button>
                </>
                <button
                    onClick={(e) => {
                        handleFilterByUserName();
                        e.preventDefault();
                    }}
                    className={styles.formSubmitBtn}
                    type="submit"
                >
                    Search
                </button>
            </div>

            <div className={styles.filterContainer}>
                {/* Course Filter */}
                <label className={`w-50 ${styles.groupFilter}`}>
                    <h6>Course Name</h6>
                    <Select
                        options={optionsDataCourses}
                        isLoading={loading} // Use the isLoading prop to indicate loading state
                        loadingMessage={() => <> loading data.... </>} // Customize the loading message

                        onChange={(selectedOptionCourse) => {
                            setSelectedCourse(selectedOptionCourse.value);
                        }}
                    />
                </label>

                {/* employs state active or inactive */}
                {/* <label className={styles.groupFilter}>
                    <h6>Certificate Delivered</h6>
                    <select
                        value={certificateDelivered}
                        onChange={handleCertificateFilter}
                        name="user state"
                        className={styles.filterBtn}
                    >
                        <option selected value="all">
                            All
                        </option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </label> */}
                {/* Department/Group Filter */}
                <label className={styles.groupFilter}>
                    <h6>Group</h6>
                    <select
                        value={selectGroup}
                        onChange={handleGroupFilter}
                        name="All Groups"
                        className={styles.filterBtn}
                    >
                        <option value="" selected>
                            All Groups
                        </option>
                        {optionsDataGroups.map((group, i) => (
                            <option key={i} value={group.value}>
                                {group.label}
                            </option>
                        ))}
                    </select>
                </label>
                {/* button to export data */}
                <Button
                    variant="contained"
                    disabled={!filterSelected} // Disable the button if no filter is selected
                    sx={{
                        background: "#183696",
                        marginTop: "1.6rem",
                        width: "11rem",
                        marginLeft: "4rem",
                        height: "3rem",

                        "&:hover": {
                            background: "#978c8cb7",
                            color: "#183696",
                        },
                    }}
                >
                    <a
                        target="_blank"
                        rel="noreferrer"
                        className={styles.progressAnchor}
                        href={`https://experience.eyouthlearning.com/api/enrollment/v1/progressUsers?course_id=${encodedCourseKey}`}
                    >
                        Export Course Data
                    </a>
                </Button>
            </div>

            <div style={{ backgroundColor: "#FAFAFA" }} className="table-responsive">
                <table
                    className="table-striped table table-hover table-bordered table-responsive "
                    style={{ color: "#183696" }}
                >
                    <thead>
                        <tr className={styles.tableHeaders}>
                            <th className={styles.tableHeaders} scope="col">
                                Course Name
                            </th>
                            <th className={styles.tableHeaders} scope="col">
                                User Name
                            </th>
                            <th className={styles.tableHeaders} scope="col">
                                Group
                            </th>
                            <th className={styles.tableHeaders} scope="col">
                                Progress
                            </th>
                            <th className={styles.tableHeaders}>Certificate Delivered</th> 
                            <th className={styles.tableHeaders}>Action</th>
                        </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "middle", position: "relative" }}>
                        {loading ? (
                            <tr>
                                <td style={{ textAlign: "center" }}>
                                    <Box
                                        style={{
                                            position: "relative",
                                            top: "50%",
                                            left: "210%",
                                            // transform: "translate(-50%, -50%)",
                                        }}
                                    >
                                        <CircularProgress
                                            size={50}
                                            style={{ color: primarycolor }}
                                        />
                                    </Box>
                                </td>
                            </tr>
                        ) : (
                            data &&
                            data?.data
                                ?.filter((user) =>
                                    certificateDelivered !== "all"
                                        ? user.user_has_passing_grade ===
                                        JSON.parse(certificateDelivered.toLocaleLowerCase())
                                        : user
                                )
                                .map((user, i) => (
                                    <tr className={styles.test} key={i}>
                                        <td>{user.course_name}</td>
                                        <td style={{ textAlign: "center", color: "blue" }}>
                                            <a href={`/userProfile/${user.student_username}`}>
                                                {user.student_name}
                                            </a>
                                        </td>
                                        <td>{user.student_group}</td>
                                        <td>
                                            <div className={styles.progress}>
                                                <Box
                                                    sx={{ position: "relative", display: "inline-flex" }}
                                                >
                                                    <CircularProgress
                                                        variant="determinate"
                                                        value={user.completion_summary}
                                                        sx={{
                                                            ...progressSx,
                                                            color: getColor(user.completion_summary),
                                                        }}
                                                    />
                                                    <Box
                                                        sx={{
                                                            top: 0,
                                                            left: 0,
                                                            bottom: 0,
                                                            right: 0,
                                                            position: "absolute",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="caption"
                                                            component="div"
                                                            color="text.secondary"
                                                        >
                                                            {`${Math.round(user.completion_summary)}%`}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                className={`${styles.circle} ${user.user_has_passing_grade
                                                    ? styles.textSuccess
                                                    : styles.textDanger
                                                    }`}
                                            >
                                                {user.user_has_passing_grade ? "Yes" : "No"}
                                            </div>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    background: "#183696",
                                                    width: "6rem",
                                                    height: "3rem",
                                                    "&:hover": {
                                                        background: "#978c8cb7",
                                                        color: "#183696",
                                                    },
                                                }}
                                            >
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className={styles.progressAnchor}
                                                    href={`https://apps.experience.eyouthlearning.com/learning/course/${user.course_key}/progress/${user.student_id}/`}
                                                >
                                                    View Progress
                                                </a>
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                        )}
                    </tbody>
                </table>
            </div>

            <Stack spacing={2} alignItems={"center"} marginTop={3}>
                <Pagination
                    page={currentPage}
                    siblingCount={2}
                    onChange={handleChange}
                    count={data?.no_of_pages}
                    sx={{
                        ".Mui-selected": {
                            background: "#183696 !important",
                            color: "white",
                        },
                    }}
                />
            </Stack>
        </>
    );
}

export default LmsTrack;
