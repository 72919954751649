import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios["Access-Control-Allow-Origin"] = "*";

const POST = async (url, data, customAllowedHeaders = false) => {
    const session = JSON.parse(localStorage.getItem("user_stu") || '{}');
    const head = customAllowedHeaders || !session.access_token || {
        headers: {
            authorization: `bearer ${session.access_token}`,
        }
    };
    const res = await axios.post(url, data, head);

    return res;
};

const PUT = async (url, data, customAllowedHeaders = false) => {
    const session = JSON.parse(localStorage.getItem("user_stu") || '{}');
    const head = customAllowedHeaders || !session.access_token || {
        headers: {
            authorization: `bearer ${session.access_token}`,
        }
    };
    const res = await axios.put(url, data, head);

    return res;
};

const PATCH = async (url, data) => {
    const session = JSON.parse(localStorage.getItem("user_stu") || '{}');
    const head = {
        headers: {
            authorization: `bearer ${session.access_token}`,
        }
    };
    const res = await axios.patch(url, data, head);

    return res;
};

const DELETE = async (url, data, dataObject) => {
    const session = JSON.parse(localStorage.getItem("user_stu") || '{}');
    const head = {
        headers: {
            authorization: `bearer ${session.access_token}`,
        }
    };
    const dataSent = dataObject ? { data: data } : { data: { data: data } };
    const res = await axios.delete(url, { ...dataSent, ...head });

    return res;
};

const GET = async (url, customAllowedHeaders = false) => {
    const session = JSON.parse(localStorage.getItem("user_stu") || '{}');
    const head = customAllowedHeaders || !session.access_token || {
        headers: {
            authorization: `bearer ${session.access_token}`,
        }
    };
    const res = await axios.get(url, head);

    return res;
};

const exportedObject = { POST, GET, PUT, DELETE, PATCH };

export default exportedObject;
