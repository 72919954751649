import * as React from "react";
import styles from "./LoadingBar.module.css";
import loadingAR from "../../assets/loadingAR.gif"
import loadingEN from "../../assets/loadingEN.gif"
import { useTranslation } from "react-i18next"

export default function LoadingBar() {

    const { i18n } = useTranslation();


    return (
        <div className={styles.loadingBar}>
            {i18n.language === "ar" ? (
                <img src={loadingAR} alt="loading..." />
            ) : (
                <img width={50} src={loadingEN} alt="loading..." />
            )
            }
        </div>
    );
}
