import React from "react";
import { useTranslation } from "react-i18next";
import AboutEyouthImage from "../../assets/About/AboutEyouthEN.jpg";
import AboutEyouthImageAR from "../../assets/About/aboutAR.png";
import introImage from "../../assets/About/Header.jpg";
import introImageAr from "../../assets/About/HeaderAR.png";
import PlatformFromTheFuture from "../../assets/About/PlatformFromTheFutur.png";
import PlatformFromTheFutureAr from "../../assets/About/photo.png";
import LastSection from "../../assets/About/lastSection.png";
import LastSectionAr from "../../assets/About/ourPartners.png";
import MobileImage from "../../assets/About/newMobileVersionsAbout.jpg";
import MobileImageAr from "../../assets/About/businessAr.png";
import line from "../../assets/line.webp";
import styles from "./About.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function About() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <LazyLoadImage
        src={i18n.language === "ar" ? introImageAr : introImage}
        alt="introImage"
        width={"100%"}
      />
      <div className={styles.backgroundMobileDiv}>
        <LazyLoadImage
          src={i18n.language === "ar" ? MobileImageAr : MobileImage}
          alt="MobileImage"
          width={"100%"}
        />
      </div>
      <LazyLoadImage
        src={i18n.language === "ar" ? AboutEyouthImageAR : AboutEyouthImage}
        alt="MobileImage"
        width={"100%"}
      />
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h3 className={`mt-5 ${styles.platformH3}`}>
          {t("platform_for_future")}
        </h3>
        <img src={line} className="mb-5" alt="line" />
        <LazyLoadImage
          src={
            i18n.language === "ar"
              ? PlatformFromTheFutureAr
              : PlatformFromTheFuture
          }
          alt="MobileImage"
          className="mb-5"
          width={"85%"}
        />
      </div>

      <div dir="ltr" className={styles.comptative}>
        <div>
          <h1 className={`text-white ${styles.textinsidecomptative}`}>
            {t("competitive")} <br></br>{t("local_language")}
          </h1>
          <p className={`text-white ${styles.textinsidecomptativep}`}>
            {t("competitve_paragraph")}
          </p>
        </div>
      </div>

      <div className="d-flex justify-content-center my-5">
        <LazyLoadImage
          width={"70%"}
          alt="our-partners"
          src={i18n.language === "ar" ? LastSectionAr : LastSection}
        />
      </div>
    </div>
  );
}

export default About;
