import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: [],
    loading: false,
    data: false
};

const slice = createSlice({
    name: "courseOutline",
    initialState,
    reducers: {
        CourseOutlineRequest: (courseOutline) => {
            courseOutline.loading = true;
            courseOutline.error = false;
        },
        CourseOutlineClear: (courseOutline) => {
            courseOutline.error = false;
            courseOutline.loading = false;
        },
        CourseOutlineSuccess: (courseOutline, action) => {
            courseOutline.loading = false;
            courseOutline.error = false;
            courseOutline.data = action.payload;
        },
        CourseOutlineError: (courseOutline, action) => {
            courseOutline.loading = false;
            courseOutline.error = action.payload;
        }
    }
});

export const { CourseOutlineClear, CourseOutlineRequest, CourseOutlineSuccess, CourseOutlineError } = slice.actions;

export default slice.reducer;
