import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: [],
    loading: false,
    data: false
};

const slice = createSlice({
    name: "downloadReq",
    initialState,
    reducers: {
        DownloadRequest: (downloadReq) => {
            downloadReq.loading = true;
            downloadReq.error = false;
        },
        DownloadClear: (downloadReq) => {
            downloadReq.error = false;
            downloadReq.loading = false;
        },
        DownloadSuccess: (downloadReq, action) => {
            downloadReq.loading = false;
            downloadReq.error = false;
            downloadReq.data = action.payload;
        },
        DownloadError: (downloadReq, action) => {
            downloadReq.loading = false;
            downloadReq.error = action.payload;
        }
    }
});

export const { DownloadRequest, DownloadClear, DownloadSuccess, DownloadError } = slice.actions;

export default slice.reducer;
