import { makeStyles } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { IconButton } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { useEffect, useState } from "react";
import { WEBSITE_URL } from "../../config/constants";
import { axiosInstace } from "../../service/axiosConfig";
import "./search.css";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  noOptions: {
    fontFamily: `"Tajawal", sans-serif`,
  },
});

export default function Searchbar(props) {
  const { t } = useTranslation();
  const [searchbar, setSearchbar] = useState([{ name: "....." }]);
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const screenWidth = window.innerWidth;

  useEffect(() => {
    axiosInstace
      .get(`/api/courses/v1/courses/search`)
      .then((res) => {
        setSearchbar(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const styles = useStyles();

  return (
    <>
      <Stack
        className={props.classes}
        sx={{
          "& .MuiAutocomplete-hasPopupIcon": {
            padding: "0",
            backgroundColor: "white",
          },
          "& .MuiOutlinedInput-root": {
            padding: "0 !important",
          },
        }}
        spacing={2}
      >
        <Autocomplete
          id="highlights-demo"
          open={open}
          onOpen={() => {
            if (inputValue) {
              setOpen(true);
            }
          }}
          onClose={() => setOpen(false)}
          onInputChange={(e, value, reason) => {
            setInputValue(value);
            if (!value) {
              setOpen(false);
            }
          }}
          onChange={(e, value) => {
            setSelected(value.course_id);
          }}
          classes={{
            noOptions: styles.noOptions,
          }}
          options={searchbar}
          noOptionsText={t("There is no course with this name")}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <div className={`${screenWidth < 768 ? "" : "d-flex"}`}>
              <TextField
                sx={{
                  "& fieldset": {
                    display: "none",
                  },
                  "& .MuiAutocomplete-popupIndicator ": {
                    display: screenWidth >= 768 && "none",
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    display: screenWidth >= 768 && "none",
                  },
                  "& .MuiAutocomplete-hasPopupIcon": {
                    padding: "0",
                  },
                }}
                placeholder={t("Search for any course")}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  endAdornment: screenWidth < 768 && (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => {
                          window.location.href = `${WEBSITE_URL}/details/${
                            searchbar.find(
                              (itemFound) => itemFound.course_id === selected
                            )?.course_id
                          }`;
                        }}
                        disabled={
                          !searchbar.find(
                            (itemFound) => itemFound.course_id === selected
                          )?.course_id
                        }
                      >
                        <SearchIcon color="action" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    const foundCourseId = searchbar.find(
                      (itemFound) => itemFound.name === event.target.value
                    )?.course_id;
                    if (foundCourseId) {
                      window.location.assign(
                        `${WEBSITE_URL}/details/${foundCourseId}`
                      );
                    } else {
                      event.target.blur();
                    }
                  }
                }}
              />
              {screenWidth >= 768 && (
                <div
                  className="search-button"
                  style={{ background: "#f8f9fa" }}
                >
                  <button
                    aria-label="button to be clicked"
                    aria-labelledby="button to be clicked"
                    style={{ color: "#fff" }}
                    disabled={
                      !searchbar.find(
                        (itemFound) => itemFound.course_id === selected
                      )?.course_id
                    }
                    className="btn btn-primary"
                  >
                    <a
                      href={`${WEBSITE_URL}/details/${
                        searchbar.find(
                          (itemFound) => itemFound.course_id === selected
                        )?.course_id
                      }`}
                    >
                      <SearchIcon />
                    </a>
                  </button>
                </div>
              )}
            </div>
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.name, inputValue, {
              insideWords: true,
            });
            const parts = parse(option.name, matches);
            return (
              <li {...props}>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                        backgroundColor: part.highlight && "blue",
                        color: part.highlight && "white",
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        />
      </Stack>
    </>
  );
}
