import Services from "../services/authServices";
import actionCall, { refreshAccessTokenHandler } from "../network/Network";
import * as request from "../store/reducers/authentication/authentication";
import * as courses from "../store/reducers/courses/courses";
import * as courseDetails from "../store/reducers/courses/courseDetails";
import * as courseReview from "../store/reducers/courses/courseReview";
import * as courseProgress from "../store/reducers/courses/courseProgress";
import * as downloadReq from "../store/reducers/courses/downloadReq";
import * as courseOutline from "../store/reducers/courses/courseOutline";
import * as courseEnroll from "../store/reducers/courses/courseEnroll";
import * as addReview from "../store/reducers/courses/addReview";
import * as contentBlocks from "../store/reducers/courses/contentBlocks";
import * as courseEnrollmentList from "../store/reducers/courses/courseEnrollmentList";

export const register = (values, redirect) => async (dispatch) =>
  actionCall(
    {
      service: () => Services.register(values),
      reqResult: { request, name: "AuthSuccess" },
    },
    dispatch,
    redirect,
    ""
  );

export const login = (values, redirect) => async (dispatch) => actionCall(
  {
    service: () => Services.login(values),

    reqResult: { request, name: "AuthSuccess" },
  },
  dispatch,
  redirect,
  ""
);


export const publicLogin = (values, redirect) => async (dispatch) => {
  return actionCall(
    {
      service: () => Services.publicLogin(values),

      reqResult: { request, name: "PublicAuthSuccess" },
    },
    dispatch,
    redirect,
    ""
  );
}
// GET
export const getCoursesList = (redirect) => async (dispatch) =>
  actionCall(
    {
      service: () => Services.getCoursesList(),
      reqResult: { request: courses, name: "CoursesSuccess" },
    },
    dispatch,
    redirect,
    ""
  );

export const getCourseEnrollmentList = (redirect) => async (dispatch) =>
  actionCall(
    {
      service: () =>
        refreshAccessTokenHandler(
          Services.getCourseEnrollmentList(),
          "user_stu"
        ),
      reqResult: {
        request: courseEnrollmentList,
        name: "CourseEnrollListSuccess",
      },
    },
    dispatch,
    redirect,
    ""
  );

export const getCourseDetails = (COURSE_KEY, redirect) => async (dispatch) =>
  actionCall(
    {
      service: () =>
        refreshAccessTokenHandler(
          Services.getCourseDetails(COURSE_KEY),
          "user_stu"
        ),
      reqResult: { request: courseDetails, name: "CourseDetailsSuccess" },
    },
    dispatch,
    redirect,
    ""
  );

export const getCourseReview = (COURSE_KEY, redirect) => async (dispatch) =>
  actionCall(
    {
      service: () => Services.getCourseReview(COURSE_KEY),
      reqResult: { request: courseReview, name: "CourseReviewSuccess" },
    },
    dispatch,
    redirect,
    ""
  );

export const getCourseProgress = (COURSE_KEY, redirect) => async (dispatch) =>
  actionCall(
    {
      service: () =>
        refreshAccessTokenHandler(
          Services.getCourseProgress(COURSE_KEY),
          "user_stu"
        ),
      reqResult: { request: courseProgress, name: "CourseProgressSuccess" },
    },
    dispatch,
    redirect,
    ""
  );

export const getCourseOutline = (COURSE_KEY, redirect) => async (dispatch) =>
  actionCall(
    {
      service: () =>
        refreshAccessTokenHandler(
          Services.getCourseOutline(COURSE_KEY),
          "user_stu"
        ),
      reqResult: { request: courseOutline, name: "CourseOutlineSuccess" },
    },
    dispatch,
    redirect,
    ""
  );

export const getContentBlocks =
  (COURSE_KEY, params, redirect) => async (dispatch) =>
    actionCall(
      {
        service: () =>
          refreshAccessTokenHandler(
            Services.getContentBlocks(COURSE_KEY, params),
            "user_stu"
          ),
        reqResult: { request: contentBlocks, name: "ContentBlocksSuccess" },
      },
      dispatch,
      redirect,
      ""
    );

export const downloadCertificate =
  (COURSE_KEY, params, redirect) => async (dispatch) =>
    actionCall(
      {
        service: () =>
          refreshAccessTokenHandler(
            Services.downloadCertificate(COURSE_KEY, params),
            "user_stu"
          ),
        reqResult: { request: downloadReq, name: "DownloadSuccess" },
      },
      dispatch,
      redirect,
      ""
    );

// POST
export const courseEnrollment = (data, redirect) => async (dispatch) =>
  actionCall(
    {
      service: () =>
        refreshAccessTokenHandler(Services.courseEnrollment(data), "user_stu"),
      reqResult: { request: courseEnroll, name: "CourseEnrollSuccess" },
    },
    dispatch,
    redirect,
    ""
  );

export const addCourseReview = (data, redirect) => async (dispatch) =>
  actionCall(
    {
      service: () =>
        refreshAccessTokenHandler(Services.addCourseReview(data), "user_stu"),
      reqResult: { request: addReview, name: "AddReviewSuccess" },
    },
    dispatch,
    redirect,
    ""
  );
