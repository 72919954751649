import CheckIcon from '@mui/icons-material/Check';
import { Alert } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { withTranslation } from "react-i18next";
import * as yup from "yup";
import { RegisterUsers, getComapnyData } from "../../../service/businessAPI/business";
import "./Registeration.css";

const validationSchema = yup.object({
  name: yup
    .string("ادخل اسمك بالكامل")
    .required("الاسم مطلوب")
    .matches(/^[a-zA-Z ]*$/, "الاسم يجب ان يكون حروف فقط"),
  username: yup
    .string("ادخل اسم المستخدم")
    .required("اسم المستخدم مطلوب")
    .matches(
      /^[a-zA-Z0-9]{3,30}$/,
      "اسم المستخدم يجب ان يحتوي فقط علي حروف و ارقام لا يجب ان يحتوي علي مسافات او علامات"
    ),
  email: yup
    .string("اكتب بريدك الالكتروني")
    .email("ادخل بريد الكتروني صحيح")
    .required("البريد الالكتروني مطلوب"),
  password: yup
    .string()
    .required(" كلمة المرور مطلوبة")
    .min(8, "كلمة المرور علي الاقل يجب ان تكون 8 حروف")
    .max(75, "كلمة المرور علي الاكثر يجب ان تكون 75 حرف"),
  phone_number: yup
    .string("يجب ان يكون رقم")
    .required("رقم الهاتف مطلوب")
    .min(7, "رقم الهاتف لا يقل عن 7 أرقام")
    .max(15, "رقم الهاتف لا يزيد عن 15 رقم")
});

function Registeration({ t }) {
  const [noOfEmployees, setNoOfEmployees] = useState();
  // const [assignTo, setAssignTo] = useState(null);

  // function handleChange(event) {
  //   console.log(event.target.files[0], "file");
  //   setFile(event.target.files[0]);
  // }

  let Company_name = "eyouth";

  useEffect(() => {

    getComapnyData(Company_name).then((result) => {
      // console.log(result.data[0].no_of_employees);
      setNoOfEmployees(result?.data[0]?.no_of_employees);
    });
  }, [Company_name]);
  const [showAlertSucces, setShowAlertSucces] = useState(false);
  const [showAlertFailed, setShowAlertFailed] = useState(false);
  // const [showAlertCSV, setShowAlertCSV] = useState(false);
  // const [showAlertFailedCSV, setShowAlertFailedCSV] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");



  const toShowAlertFailed = (message) => {
    setAlertMessage(message);
    setShowAlertFailed(true);
    setTimeout(() => {
      setShowAlertFailed(false);
    }, 5000);
  };

  // const toShowAlertSuccesCSV = () => {
  //   setShowAlertCSV(true);
  //   setTimeout(() => {
  //     setShowAlertCSV(false);
  //   }, 5000);
  // };

  // const toShowAlertFailedCSV = () => {
  //   setShowAlertFailedCSV(true);
  //   setTimeout(() => {
  //     setShowAlertFailedCSV(false);
  //   }, 5000);
  // };



  const toShowAlertSucces = () => {
    setShowAlertSucces(true);
    setTimeout(() => {
      setShowAlertSucces(false);
    }, 5000);
  };

  // const handleAssignToChange = (selectedOption) => {
  //   setAssignTo(selectedOption);
  // };





  // add phone number after signup
  const handelPhoneNumber = (data) => {
    let username = JSON.parse(localStorage.getItem("tempData"));

    const eyouthPublicClient = JSON.parse(
      localStorage.getItem("userPublic_stu") || {}
    );
    fetch(
      `https://experience.eyouthlearning.com/api/user/v1/accounts/${username}`,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/merge-patch+json",
          Authorization: `JWT ${eyouthPublicClient.access_token}`,
        },
      }
    )
      .then((response) => response.json())
      // .then((json) => console.log(json, "json phone"))
      .finally(() => localStorage.removeItem("tempData"));
    // .then(() => localStorage.removeItem("userTokenPhoneNumber"))
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      name: "",
      phone_number: "",
      password: "",
      username: ""
    },
    validationSchema: validationSchema,

    onSubmit: async (values, actions) => {

      try {
        const registrationResult = await RegisterUsers(
          values.name,
          values.username,
          values.email,
          values.password,
          values.phone_number,
          Company_name,
          noOfEmployees
        );
        if (registrationResult.success === true) {
          localStorage.setItem("tempData", JSON.stringify(values.username));
          handelPhoneNumber({ phone_number: String(values.phone_number) });
          toShowAlertSucces();
          actions.resetForm();
        } else if (registrationResult.error_code === "duplicate-email-username") {
          toShowAlertFailed("Error: Duplicate email and username");
        } else if (registrationResult.error_code === "duplicate-email") {
          toShowAlertFailed("Error: Duplicate email");
        } else if (registrationResult.error_code === "duplicate-username") {
          toShowAlertFailed("Error: Duplicate username");
        } else if (registrationResult.error === "You have exceeded the limit for registrations.") {
          toShowAlertFailed("Error: You have exceeded the limit for registrations");
        } else {
          toShowAlertFailed("Unknown error: " + registrationResult.error);
        }
      } catch (error) {
        console.error("Error during registration:", error);
      }
    },
  });
  // const handelCSV = async (e) => {
  //   e.preventDefault();

  //   try {
  //     await SentCSVData(Company_name, file);
  //     toShowAlertSuccesCSV();
  //     setFile(null);
  //   } catch (error) {
  //     console.error("Error during CSV upload:", error);
  //     toShowAlertFailedCSV();
  //   }
  // };

  return (
    <>
      <div className="col-md-9 d-flex justify-content-center align-items-center flex-column my-1 ">
        <div className="form w-100">
          <h5 style={{ color: "rgb(40, 59, 145)" }}> Register User </h5>

          {/* {assignTo && ( */}
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex">
                <div className="signupFormWidth me-5">
                  <label className="justify-content-start d-flex h5 mt-3">
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="error text-danger">
                      {formik.touched.name && formik.errors.name}
                    </div>
                  )}
                </div>
                <div className="signupFormWidth">
                  <label className="justify-content-start d-flex h5 mt-3">
                    {t("Username")}
                  </label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className="form-control"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                  />
                  {formik.touched.username && formik.errors.username && (
                    <div className="error text-danger">
                      {formik.touched.username && formik.errors.username}
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex">
                <div className="signupFormWidth me-5" >
                  <label className="d-flex justify-content-start h5 mt-3">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="form-control"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="error text-danger">
                      {formik.touched.email && formik.errors.email}
                    </div>
                  )}
                </div>
                <div className="signupFormWidth">
                  <label className="justify-content-start d-flex h5 mt-3">
                    {t("Phone")}
                  </label>

                  <input
                    type="number"
                    name="phone_number"
                    id="phone_number"
                    className="form-control"
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phone_number &&
                      Boolean(formik.errors.phone_number)
                    }
                    helperText={
                      formik.touched.phone_number && formik.errors.phone_number
                    }
                  />
                  {formik.touched.phone_number &&
                    formik.errors.phone_number && (
                      <div className="error text-danger">
                        {formik.touched.phone_number &&
                          formik.errors.phone_number}
                      </div>
                    )}
                </div>
              </div>

              <div className="signupFormWidth">
                <label className="justify-content-start d-flex h5 mt-3">
                  {t("Password")}
                </label>

                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={
                    formik.touched.password && formik.errors.password
                  }
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="error text-danger">
                    {formik.touched.password && formik.errors.password}
                  </div>
                )}
              </div>

              <div className="d-flex flex-column justify-content-start align-items-start mt-4">

              </div>
              {showAlertSucces && (
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                  User Registered Successfully
                </Alert>

              )}
              {showAlertFailed && (
                <div className="w-100">
                  <Alert severity="error">{alertMessage}</Alert>
                </div>
              )}

              <div>

              </div>
              <div className="d-flex justify-content-center">
                <Button
                  type="submit"
                  className="btn btn-primary w-25 mt-4 signup--btn"
                  size='small'
                >
                  Register
                </Button>
              </div>

            </form>
          </div>
          {/* )} */}

        </div>
      </div>
    </>
  );
}
export default withTranslation()(Registeration);
