import React from "react";
import { useTranslation } from "react-i18next";
import { CATEGORIES_LIST } from "../../config/constants";

const CategoriesList = ({ isLive }) => {
  const { t } = useTranslation();
  const baseFilterUrl = isLive
    ? `/all-courses?pacing=false&org=`
    : `/all-courses?pacing=true&org=`;
  return (
    <div className="navigation-content">
      <div className="d-flex justify-content-start flex-column">
        {CATEGORIES_LIST.map((category, index) => (
          <a
            key={index}
            href={baseFilterUrl + category.toLowerCase()}
            className="col-md-12"
          >
            {t(category)}
          </a>
        ))}
      </div>
    </div>
  );
};

export default CategoriesList;
