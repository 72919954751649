import React, { useEffect, useState } from 'react';
// import styles from './CourseEnrollment.module.css';
import { Autocomplete, Button, Checkbox, Stack, TextField } from '@mui/material';
import Alert from "@mui/material/Alert";
import Select from 'react-select';
import addIcon from '../../../assets/Business/addIcon.png';
import { EnrollUsersInCourse, getAllCoursesSearch, getCompanyGroups, getUsers } from '../../../service/businessAPI/business';


function CourseEnrollment() {
    const [assignTo, setAssignTo] = useState(null);
    const handleAssignToChange = (selectedOption) => {
        setAssignTo(selectedOption);
    };

    const eyouthPublicClient = JSON.parse(
        localStorage.getItem("userPublic_stu") || {}
    );
    let CompanyName = "eyouth"
    let token = eyouthPublicClient.access_token
    const [optionsDataGroups, setOptionsDataGroups] = useState([]);
    const [optionsDataCourses, setOptionsDataCourses] = useState([]);
    const [optionsDataUsers, setOptionsDataUsers] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState("");

    const [selectedCourse, setSelectedCourse] = useState([]);
    const [emailStudents, setEmailStudents] = useState(false); // State for checkbox
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertError, setshowAlertError] = useState(false);
    const [showAlertUnEnroll, setShowAlertUnEnroll] = useState(false);
    const [showAlertErrorUnEnroll, setshowAlertErrorUnEnroll] = useState(false);
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [loadingGroups, setLoadingGroups] = useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    // error alert  enroll
    const toShowAlertError = () => {
        setshowAlertError(true);
        setTimeout(() => {
            setshowAlertError(false);
        }, 5000);
    };
    const toShowAlert = () => {
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    };

    // unenroll
    const toShowAlertErrorUnEnroll = () => {
        setshowAlertErrorUnEnroll(true);
        setTimeout(() => {
            setshowAlertErrorUnEnroll(false);
        }, 5000);
    };
    // alert 
    const toShowAlertUnEnroll = () => {
        setShowAlertUnEnroll(true);
        setTimeout(() => {
            setShowAlertUnEnroll(false);
        }, 3000);
    };
    useEffect(() => {
        setIsButtonDisabled(!selectedCourse.length || (!selectedGroup.length && !selectedUsers.length));
    }, [selectedCourse, selectedGroup, selectedUsers]);


    useEffect(() => {
        getAllCoursesSearch()
            .then((result) => {
                setLoading(true);

                const formattedOptionsCourses = result.map(item => ({
                    value: item.course_id,
                    label: item.name,
                }))
                setOptionsDataCourses(formattedOptionsCourses)
                setLoading(false);

            })
            .catch((error) => {
                console.error('API Error:', error);
            });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getCompanyGroups(CompanyName)
            .then((result) => {
                setLoadingGroups(true)
                const formattedOptionsGroups = result.data.map(item => ({
                    value: item.group_name,
                    label: item.group_name,
                }));

                setOptionsDataGroups(formattedOptionsGroups);
                setLoadingGroups(false)

            })
            .catch((error) => {
                console.error('API Error:', error);
            });
        // eslint-disable-next-line
    }, [CompanyName]);

    useEffect(() => {
        getUsers('1', CompanyName, "20000", "", "", "")
            .then((result) => {
                setLoadingUsers(true)
                const formattedOptionsUsers = result.results.map(item => ({
                    value: item.email,
                    label: item.username,
                }));
                const sliceformattedOptionsUsers = formattedOptionsUsers.slice(0, 500)
                console.log('sliceformattedOptionsUsers', sliceformattedOptionsUsers)

                setOptionsDataUsers(formattedOptionsUsers);
                setLoadingUsers(false)
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
        // eslint-disable-next-line
    }, []);
    const customFilter = (option, searchText) => {
        if (
            option.label.toLowerCase().includes(searchText.toLowerCase()) ||
            option.value.toLowerCase().includes(searchText.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handleEnrollment = async () => {
        try {
            // console.log(CompanyName, selectedCourse, selectedGroup, emailStudents, token, assignTo.value, "enroll");
            const enrollResult = await EnrollUsersInCourse(CompanyName, selectedCourse, assignTo.value === "group" ? selectedGroup : selectedUsers, emailStudents, token, assignTo.value, "enroll");
            // console.log(enrollResult, "res enroll");
            if (enrollResult === 200) {
                toShowAlert();
                setSelectedCourse([]);
                setSelectedGroup([]);
                setAssignTo(null);
                setEmailStudents(false);

            } else {
                toShowAlertError()
            }
        } catch (error) {
            console.error('API Error:', error);
        }
    };
    const handleUnEnrollment = async () => {
        try {
            // console.log(CompanyName, selectedCourse, selectedGroup, emailStudents, token, assignTo.value, 'unenroll');
            const enrollResult = await EnrollUsersInCourse(CompanyName, selectedCourse, assignTo.value === "group" ? selectedGroup : selectedUsers, emailStudents, token, assignTo.value, 'unenroll');
            // console.log(enrollResult, "res enroll");
            if (enrollResult === 200) {
                toShowAlertUnEnroll();
                setSelectedCourse([]);
                setSelectedGroup([]);
                setAssignTo(null);
                setEmailStudents(false);

            } else {
                toShowAlertErrorUnEnroll()
            }
        } catch (error) {
            console.error('API Error:', error);
        }
    };

    return (
        <div className='container'>
            <div className='d-flex align-items-center mt-4 '>
                <img width='25px' alt='add-icon' src={addIcon} />
                <h4 className='mx-2 my-0 p-0' style={{ color: "#283B91" }}> Add users to Course </h4>
            </div>
            <div className='my-5 d-flex justify-content-between'>
                <div className='col-5'>
                    <h6>Course Name</h6>
                    <Select
                        options={optionsDataCourses}
                        isLoading={loading} // Use the isLoading prop to indicate loading state
                        loadingMessage={() => <> loading data.... </>} // Customize the loading message

                        onChange={(selectedOptionCourse) => {
                            setSelectedCourse(selectedOptionCourse.value);
                        }}
                    />
                </div>
            </div>

            <div>
                <div>
                    <h4 className='mx-2 my-2 p-0' style={{ color: "#283B91" }}>Assign to</h4>
                    <Select
                        className='w-25'
                        options={[
                            { value: 'group', label: 'Groups' },
                            { value: 'identifiers', label: 'Users' }
                        ]}
                        onChange={handleAssignToChange}
                        isSearchable={false}
                    />
                    {assignTo && (
                        <div>
                            {assignTo.value === 'group' ? (
                                <>
                                    <div className='my-5 d-flex'>

                                        <div className='col-5'>
                                            <h6> Choose Groups</h6>
                                            <Select
                                                isLoading={loadingGroups} // Use the isLoading prop to indicate loading state
                                                loadingMessage={() => <> loading data.... </>} // Customize the loading message
                                                options={optionsDataGroups} onChange={(selectedOption) => {
                                                    setSelectedGroup(selectedOption.value);
                                                    // console.log(selectedOption.value, 'Selected Group:',)
                                                }} />
                                        </div>

                                    </div>

                                </>
                            ) : (
                                <>
                                    <div className='my-5 d-flex'>
                                        <div className='col-5'>
                                            <h6> Choose users</h6>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={optionsDataUsers}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} label="Users" />}

                                                onChange={(event, selectedOptions) => {
                                                    if (Array.isArray(selectedOptions)) {
                                                        const selectedUserLabels = selectedOptions.map(option => option.value);
                                                        setSelectedUsers(selectedUserLabels.join(', '));
                                                    } else if (selectedOptions) {
                                                        setSelectedUsers(selectedOptions.value);
                                                    }
                                                }}
                                                filterOption={customFilter}
                                            />
                                        </div>
                                    </div>
                                </>)}
                        </div>
                    )}
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "space-between" }} >
                    <div>
                        <Checkbox
                            className='px-0'
                            id='checkBoxAccept'
                            checked={emailStudents}
                            onChange={() => setEmailStudents(!emailStudents)}
                        />
                        <label htmlFor='checkBoxAccept'>Inform Users by Email</label>
                    </div>
                    <div className='w-50 d-flex justify-content-around'>
                        <Button onClick={handleEnrollment} disabled={isButtonDisabled} variant="contained" >Enroll</Button>
                        <Button onClick={handleUnEnrollment} disabled={isButtonDisabled} variant="contained" >UnEnroll</Button>

                    </div>


                </div>
            </div>
            {showAlert && (
                <Stack
                    className="my-2 mx-auto "
                    sx={{ width: { sx: "35%", sm: "40%", md: "38%", lg: "38%" } }}
                >
                    <Alert severity="success">Course Added successfully</Alert>
                </Stack>
            )}
            {showAlertError && (
                <Stack
                    className="my-2 mx-auto "
                    sx={{ width: { sx: "35%", sm: "40%", md: "38%", lg: "38%" } }}
                >
                    <Alert severity="error">Enrollment failed. Please try again.</Alert>
                </Stack>
            )}
            {showAlertUnEnroll && (
                <Stack
                    className="my-2 mx-auto "
                    sx={{ width: { sx: "35%", sm: "40%", md: "38%", lg: "38%" } }}
                >
                    <Alert severity="success">User UnEnroll successfully </Alert>
                </Stack>
            )}
            {showAlertErrorUnEnroll && (
                <Stack
                    className="my-2 mx-auto "
                    sx={{ width: { sx: "35%", sm: "40%", md: "38%", lg: "38%" } }}
                >
                    <Alert severity="error">UnEnrollment failed. Please try again.</Alert>
                </Stack>
            )}
        </div>
    );
}

export default CourseEnrollment;
