import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import * as React from 'react';
const actions = [
    { icon: <AppleIcon />, name: 'APP Store', link: "https://apps.apple.com/sa/app/eyouth/id1643413327?platform=iphone" },
    { icon: <AndroidIcon />, name: 'Google Play Store', link: "https://play.google.com/store/apps/details?id=org.eyouthlearning.mobile&pcampaignid=web_share" },
];
export default function DownloadButtons() {

    function openInNewTab(url) {
        window.open(url, '_blank', 'noopener,noreferrer')
    }


    return (
        <Box sx={{ position: "fixed", right: "10px", bottom: "50%", zIndex: "9999999999999" }}>
            <SpeedDial
                ariaLabel="SpeedDial"
                icon={<SmartphoneIcon />}
                sx={{
                    "& .MuiSpeedDial-fab": {
                        background: "#243a93",
                        "&:hover": {
                            background: "#ffcf5b"
                        }
                    },
                }}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => {
                            openInNewTab(action.link)
                        }}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
}