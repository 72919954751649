import Groups2Icon from "@mui/icons-material/Groups2";
import {
    Alert,
    Box,
    CircularProgress,
    Pagination,
    Snackbar,
    Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import { AddMemberToGroup } from "../../../components/AddMemberToGroup/AddMemberToGroup";
import UserCards from "../../../components/UserCards/UserCards.jsx";
import {
    deleteUsersFromGroup,
    getCompanyGroups,
    getUsers,
} from "../../../service/businessAPI/business.js";
import styles from "./GroupDetails.module.css";

const GroupDetails = () => {
    const [data, setData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [notLoading, setNotLoading] = useState(false);
    // eslint-disable-next-line
    const [optionsDataGroups, setOptionsDataGroups] = useState([]);
    const [userToDelete, setUserToDelete] = useState(null); // State to keep track of user to delete

    const [state, setState] = useState({
        open: false,
        vertical: "top",
        horizontal: "right",
    });

    //Modal on and off
    const [show, setShow] = useState(false);

    const handlePopupClose = () => setShow(false);
    const handlePopupShow = () => setShow(true);

    //get the group URL ID
    const { groupId } = useParams();

    const handleGroupId = () => {
        const department = groupId.split("-");
        if (department.length > 1) {
            return department[department.length - 1];
        } else {
            return null;
        }
    };
    const groupName = handleGroupId(groupId);

    const { vertical, horizontal, open } = state;

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const handleChange = (value) => {
        setCurrentPage(value);

    };
    // eslint-disable-next-line
    const [searchparams, setSearchparams] = useSearchParams();

    // const companyName = localStorage.getItem("company_name");
    const companyName = "eyouth";
    // let primarycolor = localStorage.getItem("primaryColor");
    let primarycolor = "#243a93";

    useEffect(() => {
        setLoading(false);
        getUsers("1", companyName, "10", "", "", `${groupName}`)
            .then((result) => {
                setData(result);
                setLoading(true);
                setNotLoading(true);
            })
            .catch((error) => {
                console.error("API Error: ", error);
                setLoading(true);
            });
        // eslint-disable-next-line
    }, [currentPage, searchparams, groupId]);

    useEffect(() => {
        getCompanyGroups(companyName)
            .then((result) => {
                const formattedOptionsGroups = result.data.map((item) => ({
                    value: item.group_name,
                    label: item.group_name,
                }));
                setOptionsDataGroups(formattedOptionsGroups);
            })
            .catch((error) => {
                console.error("API Error:", error);
            });
    }, [companyName]);

    const handleClickDelete = async (userName, group) => {
        // Store user details to delete
        setUserToDelete({ userName, group });
        handlePopupShow(); // Show modal
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteUsersFromGroup([userToDelete.userName], userToDelete.group);
            getUsers("1", companyName, "10", "", "", `${groupName}`)
                .then((result) => {
                    setData(result);
                    setLoading(true);
                })
                .catch((error) => {
                    console.error("API Error: ", error);
                    setLoading(true);
                });
        } catch (error) {

            console.error("Error deleting user:", error);
        } finally {
            handlePopupClose(); // Close modal regardless of success or failure
        }
    };

    return (
        <>
            <Modal centered show={show} onHide={handlePopupClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this {[userToDelete?.userName]}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlePopupClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Snackbar
                key={vertical + horizontal}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={20000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                    Your Request is being processed, Please don't refresh the page.
                </Alert>
            </Snackbar>

            <div className={styles.businessInfoCards}>
                <UserCards
                    count={data.count}
                    title="Group Members"
                    Icon={<Groups2Icon style={{ fontSize: "40px" }} />}
                />
                <div className={styles.groupName}>
                    <h3 style={{ color: primarycolor }}>{groupName}</h3>
                </div>
                <AddMemberToGroup groupName={groupName} />
            </div>
            <div style={{ backgroundColor: "#FAFAFA" }} className="table-responsive">
                <table
                    className="table-striped table table-hover table-bordered table-responsive "
                    style={{ color: "#183696" }}
                >
                    <thead>
                        <tr className={styles.tableHeaders}>
                            <th
                                className={styles.tableHeaders}
                                style={{ color: primarycolor }}
                                scope="col"
                            >
                                ID
                            </th>
                            <th
                                className={styles.tableHeaders}
                                style={{ color: primarycolor }}
                                scope="col"
                            >
                                Name
                            </th>
                            <th
                                className={styles.tableHeaders}
                                style={{ color: primarycolor }}
                                scope="col"
                            >
                                Email
                            </th>
                            <th
                                className={styles.tableHeaders}
                                style={{ color: primarycolor }}
                                scope="col"
                            >
                                Group Name
                            </th>
                            <th
                                className={styles.tableHeaders}
                                style={{ color: primarycolor }}
                                scope="col"
                            >
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "middle" }}>
                        {!loading ? (
                            <tr>
                                <td style={{ textAlign: "center" }}>
                                    <Box
                                        style={{
                                            position: "absolute",
                                            top: "58%",
                                            left: "55%",
                                        }}
                                    >
                                        <CircularProgress
                                            size={50}
                                            style={{ color: primarycolor }}
                                        />
                                    </Box>
                                </td>
                            </tr>
                        ) : (
                            data?.results?.map((user, i) => (
                                <tr key={i}>
                                    <th scope="row">{user.id}</th>
                                    <td>
                                        {" "}
                                        <a href={`/userProfile/${user.username}`}>{user.name}</a>
                                    </td>
                                    <td>{user.email}</td>
                                    <td>{user.group}</td>
                                    <td className="text-center">
                                        <button
                                            className={styles.deleteUserBtn}
                                            onClick={() =>
                                                handleClickDelete(user.username, groupName)
                                            }
                                        >
                                            Remove user
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            <Stack spacing={2} alignItems={"center"} marginTop={3}>
                <Pagination
                    page={currentPage}
                    siblingCount={2}
                    onChange={handleChange}
                    count={data.num_pages}
                    sx={{
                        ".Mui-selected": {
                            background: "#183696 !important",
                            color: "white",
                        },
                    }}
                />
            </Stack>
        </>
    );
};

export default GroupDetails;
