import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: [],
    loading: false,
    data: false
};

const slice = createSlice({
    name: "courses",
    initialState,
    reducers: {
        CoursesRequest: (courses) => {
            courses.loading = true;
            courses.error = false;
        },
        CoursesClear: (courses) => {
            courses.error = false;
            courses.loading = false;
        },
        CoursesSuccess: (courses, action) => {
            courses.loading = false;
            courses.error = false;
            courses.data = action.payload;
        },
        CoursesError: (courses, action) => {
            courses.loading = false;
            courses.error = action.payload;
        }
    }
});

export const { CoursesClear, CoursesRequest, CoursesSuccess, CoursesError } = slice.actions;

export default slice.reducer;
