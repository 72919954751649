import { combineReducers } from "@reduxjs/toolkit";
import user from "./reducers/authentication/authentication";
import courses from "./reducers/courses/courses";
import courseDetails from "./reducers/courses/courseDetails";
import courseReview from "./reducers/courses/courseReview";
import courseProgress from "./reducers/courses/courseProgress";
import courseOutline from "./reducers/courses/courseOutline";
import courseEnroll from "./reducers/courses/courseEnroll";
import downloadReq from "./reducers/courses/downloadReq";
import addReview from "./reducers/courses/addReview";
import contentBlocks from "./reducers/courses/contentBlocks";
import courseEnrollmentList from "./reducers/courses/courseEnrollmentList";
import userLocationReducer from "./reducers/userLocation/userLocationReducer";

const rootReducer = combineReducers({
  user,
  courses,
  courseDetails,
  courseReview,
  downloadReq,
  courseProgress,
  courseOutline,
  courseEnroll,
  addReview,
  contentBlocks,
  courseEnrollmentList,
  userLocationReducer
});

export default rootReducer;
