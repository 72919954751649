import createCache from "@emotion/cache";
import { createTheme } from "@mui/material/styles";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";

const RTLTheme = createTheme({
    direction: "rtl"
});
const LTRTheme = createTheme({
    direction: "ltr"
});

const cacheLTR = createCache({
    key: "muiltr",
    prepend: true
});

const cacheRTL = createCache({
    key: "muirtl",
    prepend: true,
    // prefixer is the only stylis plugin by default, so when
    // overriding the plugins you need to include it explicitly
    // if you want to retain the auto-prefixing behavior.
    
    stylisPlugins: [prefixer, rtlPlugin]
});

export { LTRTheme, RTLTheme, cacheLTR, cacheRTL };
