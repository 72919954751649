// actions.js

// Action types
export const FETCH_DATA_START = 'FETCH_DATA_START';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';
export const FETCH_DATA_LOADING = 'FETCH_DATA_LOADING';

// Action creators
export const fetchDataStart = () => ({
    type: FETCH_DATA_START,
});

export const fetchDataLoading = () => ({
    type: FETCH_DATA_LOADING,
});
export const fetchDataSuccess = (data) => ({
    type: FETCH_DATA_SUCCESS,
    payload: data,
});

export const fetchDataFailure = (error) => ({
    type: FETCH_DATA_FAILURE,
    payload: error,
});

// Async action creator
export const fetchData = () => async (dispatch) => {
    dispatch(fetchDataStart());
    fetch("https://api.ipify.org/")
        .then(res => res.text())
        .then(ip => {
            // console.log("ip", ip)
            // fetch(`https://api.iplocation.net/?ip=${ip}`)
            // fetch(`http://ip-api.com/json/${ip}`)
            fetch(`https://ipapi.co/${ip}/json/`)
                .then(ipResult => ipResult.json())
                .then(jsonData => {
                    // console.log("country Code", jsonData.country_code)
                    if (!["AE", "SA", "EG"].includes(jsonData.country_code)) dispatch(fetchDataSuccess("Other"));
                    else dispatch(fetchDataSuccess(jsonData.country_code));
                }).catch(error => {
                    dispatch(fetchDataFailure(error.message));
                }).finally(() => {
                    dispatch(fetchDataLoading());
                })

        }).catch(err => {
            dispatch(fetchDataFailure(err.message));
        })
};
