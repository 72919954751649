import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: [],
    loading: false,
    data: false
};

const slice = createSlice({
    name: "contentBlocks",
    initialState,
    reducers: {
        ContentBlocksRequest: (contentBlocks) => {
            contentBlocks.loading = true;
            contentBlocks.error = false;
        },
        ContentBlocksClear: (contentBlocks) => {
            contentBlocks.error = false;
            contentBlocks.loading = false;
        },
        ContentBlocksSuccess: (contentBlocks, action) => {
            contentBlocks.loading = false;
            contentBlocks.error = false;
            contentBlocks.data = action.payload;
        },
        ContentBlocksError: (contentBlocks, action) => {
            contentBlocks.loading = false;
            contentBlocks.error = action.payload;
        }
    }
});

export const { ContentBlocksClear, ContentBlocksRequest, ContentBlocksSuccess, ContentBlocksError } = slice.actions;

export default slice.reducer;
