import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Alert, AlertTitle } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { axiosInstace } from '../../service/axiosConfig';

function RandomCode() {
  const [randomCode, setRandomCode] = useState(null);
  const [userData, setUserData] = useState(null);
  const session = JSON.parse(localStorage.getItem("user_stu"));
  const navigate = useNavigate();

  // List of predefined codes
  const predefinedCodes = ["8282", "3489", "2345", "1923", "4432", "1232"];

  // Function to choose a random code from the predefined list
  const chooseRandomCode = () => {
    const index = Math.floor(Math.random() * predefinedCodes.length);
    return predefinedCodes[index];
  };

  function fetchingUserData() {
    axiosInstace
      .get(`/api/user/v1/accounts/${session.username}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${session.access_token}`,
        },
      })
      .then((res) => {
        setUserData(res.data);
      })
      .catch((err) => console.log({ err }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Authorization", `bearer ${session.access_token}`);
      myHeaders.append("Cookie", "csrftoken=nVddr9vjx3FSzqapqyjtH7Xr0hNZ8GUnKeVUAIQ0dPD1wwZO65WJSr2IodB9OsYY; sessionid=1|y2sgq4se6wb8mfjfo7uz3ufcklchgddn|4oSPBfs6xOi0|IjhkNjFlZmRlNmViMjQyNGVlYTAyYjEzMjVkNzYzNTc1NTYzZDQ1Y2Q2MWRhYTc0MmU2ZjYwMWQ2NzNiMTA3OWYi:1rzHM5:D9rdKFwtKbDWV9IcaDrF3RQryYE");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      try {
        const response = await fetch(`https://experience.eyouthlearning.com/api/user/v1/accounts/${session.username}`, requestOptions);
        const result = await response.json();
        console.log(result, "result");
        setUserData(result);
      } catch (error) {
        console.error(error);
      }
    };

    if (!session) {
      return navigate("/signin");
    }

    fetchData();
    // eslint-disable-next-line
  }, []); // Empty dependency array to run the effect only once

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Create form data
      const formdata = new FormData();
      formdata.append("username", session.username);
      formdata.append("email", userData.email);
      formdata.append("phonenumber", userData.phone_number);
      formdata.append("userid", values.userid); // Append userid field

      // Get the chosen random code from the predefined list
      const code = chooseRandomCode();
      const id = values.id;

      formdata.append("code", id);

      // Access the file object from the file input field
      const fileInput = document.querySelector('input[type="file"]');
      const file = fileInput.files[0];

      // Extract filename from the file object
      const fileName = file.name;

      // Append image data with the extracted filename
      formdata.append("image", file, fileName);

      // Make API request
      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };
      const response = await fetch("https://python.eyouthlearning.com/eyouth/form", requestOptions);
      const result = await response.json();
      console.log(result, "res");

      // Update state with the random code
      setRandomCode(code);

    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
      // Reset the form
      resetForm();
    }
  };

  useEffect(() => {
    if (!session) {
      return navigate("/signin");
    }
    fetchingUserData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container mt-4">
      <h1>Form</h1>
      <Formik
        initialValues={{ image: '', id: '', userid: '' }} // Add userid to initialValues
        validationSchema={Yup.object({
          id: Yup.string()
            .required('Required'),
          userid: Yup.string() // Add userid to validation schema
            .required('Required'),
          image: Yup.mixed().required('Required'),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="mb-3">
              <label htmlFor="image" className="form-label">Upload Image</label>
              <Field name="image" type="file" className="form-control" />
              <ErrorMessage name="image" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
              <label htmlFor="id" className="form-label">Enter ID</label>
              <Field name="id" type="text" className="form-control" />
              <ErrorMessage name="id" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
              <label htmlFor="userid" className="form-label">Enter User ID</label> {/* Add User ID field */}
              <Field name="userid" type="text" className="form-control" />
              <ErrorMessage name="userid" component="div" className="text-danger" />
            </div>
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              Submit
            </button>
          </Form>
        )}
      </Formik>
      {/* Material-UI Alert to display the random code */}
      {randomCode && (
        <Alert severity="success" className="mt-4">
          <AlertTitle>Submit Success!</AlertTitle>
          Your code is: {randomCode}
        </Alert>
      )}
    </div>
  );
}

export default RandomCode;
