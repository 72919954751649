import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import LanguageIcon from "@mui/icons-material/Language"; // Material-UI Language icon
import Services from "../../servises/services";
import styles from "./LangButton.module.css";
import { components } from "react-select";

function LangButton() {
  const { i18n } = useTranslation();
  const session = JSON.parse(localStorage.getItem("user_stu") || "{}");

  const languageOptions = [
    { value: "en", label: "English" },
    { value: "ar", label: "العربية" },
  ];

  const [currentLang, setCurrentLang] = React.useState(() => {
    return localStorage.getItem("i18nextLng") || i18n.language;
  });

  const handleChange = async (languageCode) => {
    setCurrentLang(languageCode);

    if (Object.keys(session).length > 0) {
      try {
        localStorage.setItem("selectedLanguage", languageCode);
        await Services.changeUserPrefLanguage(session?.username, languageCode);
      } catch {
        console.log("error in set language");
      }
    }

    try {
      await Services.handelChangeLanguage(
        languageCode,
        session?.username,
        session?.access_token
      );
    } catch {
      console.log("error in change client language");
    }
  };

  React.useEffect(() => {
    i18n.changeLanguage(currentLang);
    document.documentElement.lang = `${i18n.language === "en" ? "en" : "ar"}`;
    document.dir = i18n.language === "en" ? "ltr" : "rtl";
    // eslint-disable-next-line
  }, [currentLang]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <LanguageIcon className={styles["world-icon"]} />
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "30px",
      width: "8vw",
      '@media (max-width: 768px)': {
        width: "26vw !important",
        // maxWidth: "200px",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "40px",
      padding: "0px",
      marginLeft: "6px",
      marginTop: "-4px",
    }),
  };

  return (
    <>
      <Select
        // className={styles["switch-lang-dropdown"]}
        options={languageOptions}
        onChange={(selectedOption) => handleChange(selectedOption.value)}
        value={languageOptions.find((option) => option.value === currentLang)}
        components={{ DropdownIndicator }}
        styles={customStyles}
      />
    </>
  );
}

export default LangButton;